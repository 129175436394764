/*
 * @Author: rh 674704456@qq.com
 * @Date: 2022-12-15 17:50:29
 * @LastEditors: rh 674704456@qq.com
 * @LastEditTime: 2022-12-19 22:01:41
 * @FilePath: \water-museum\src\permission.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import router from './router'
import { isMaintaining } from "@/api/index";

// const whiteList = ['/login', '/auth-redirect', '/bind', '/register']

//maintainMode维护模式1维护模式2正常模式
router.beforeEach((to, from, next) => {
  console.log(to)
  if (to.path == '/maintainMode') {
    next()
  }
  to.path !== '/maintainMode' && isMaintaining().then((res)=>{
    localStorage.setItem('maintainMode', res.data.isMaintaining)
    // 1 维护中 0 正常
    if (localStorage.getItem('maintainMode') != 1) {
      next()
    } else {
      next({ path: '/maintainMode' })
    }
  })
  
})

router.afterEach(() => {
})

