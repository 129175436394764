<!--
 * @Author: rh 674704456@qq.com
 * @Date: 2022-11-19 14:23:28
 * @LastEditors: rh 674704456@qq.com
 * @LastEditTime: 2022-12-23 11:34:37
 * @FilePath: \water-museum\src\App.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div>
    <router-view />
  </div>
</template>

<script>
import { isMaintaining, isBlack, addVisitors } from "@/api/index";

export default {
  name: "App",
  created() {
    isMaintaining().then((res) => {
      localStorage.setItem("maintainMode", res.data.isMaintaining);
      // 1 维护中 0 正常
    });
    isBlack().then((res) => {
      //黑白模式 0 关闭 1 开启
      localStorage.setItem("isBlack", res.msg);
      document.body.className = res.msg == "1" ? "isBlack" : "";
    });
    addVisitors().then((res) => {
      console.log(res);
    });
    if (!window.timer) {
      window.timer = setInterval(() => {
        addVisitors().then((res) => {
          console.log(res);
        });
      }, 600000);
    }
  },
};
</script>
<style scoped>
.container {
  background: url(./assets/ejy_secbg.png) no-repeat;
  background-size: 100% 100%;
}
</style>
