<template>
  <div id="app" class="container">
    <div class="header">
      <div class="main">
        <div class="logo">
          <a href="/home" target="_self">
            <img src="../assets/logo.png" alt="" />
          </a>
        </div>
        <ul class="nav">
          <li>
            <router-link to="/redirect/home" target="_self">首页</router-link>
          </li>
          <!--  一级栏目循环开始  -->
          <li data-nav="1">
            走进水博
            <!--是否有子栏目-->
            <dl style="left: -275px">
              <dd>
                <router-link to="/enterMuseum/speech" target="_blank"
                  >馆长致辞</router-link
                >
              </dd>
              <dt></dt>
              <dd>
                <router-link to="/enterMuseum/speech" target="_blank"
                  >组织结构</router-link
                >
              </dd>
              <dt></dt>
              <dd>
                <router-link to="/enterMuseum/survey" target="_blank"
                  >水博概况</router-link
                >
              </dd>
              <dt></dt>
              <dd>
                <router-link to="/enterMuseum/duty" target="_blank"
                  >主要职责</router-link
                >
              </dd>
              <dt></dt>
              <dd>
                <router-link to="/enterMuseum/chronicleOfEvents" target="_blank"
                  >大事记</router-link
                >
              </dd>
            </dl>
          </li>
          <li>
            <router-link to="/information/index" target="_blank"
              >水博资讯</router-link
            >
          </li>
          <li>
            <router-link to="/overview/index" target="_blank"
              >水利纵览</router-link
            >
          </li>

          <li>
            <router-link to="/dynamic/index" target="_blank"
              >文博动态</router-link
            >
          </li>

          <li data-nav="1">
            展陈大观
            <dl style="left: -81.5px">
              <dd>
                <router-link to="/exhibition/introduce" target="_blank"
                  >展览介绍</router-link
                >
              </dd>

              <dt></dt>

              <dd>
                <router-link to="/exhibition/collections" target="_blank"
                  >藏品鉴赏</router-link
                >
              </dd>
            </dl>
          </li>

          <li data-nav="1">
            水利文化
            <dl style="left: -339.5px">
              <dd>
                <router-link to="/culture/education" target="_blank"
                  >社会教育</router-link
                >
              </dd>
              <dt></dt>
              <dd>
                <router-link to="/culture/heritage" target="_blank"
                  >水利遗产</router-link
                >
              </dd>
              <dt></dt>
              <dd>
                <router-link to="/culture/scholarship" target="_blank"
                  >学术研究</router-link
                >
              </dd>
              <dt></dt>
              <dd>
                <router-link to="/culture/communicate" target="_blank"
                  >交流合作</router-link
                >
              </dd>
              <dt></dt>
              <dd>
                <router-link to="/culture/culturalBoutique" target="_blank"
                  >文创精品</router-link
                >
              </dd>

              <dt></dt>

              <dd>
                <router-link to="/culture/popularScience" target="_blank"
                  >水利科普</router-link
                >
              </dd>
            </dl>
          </li>

          <li data-nav="1">
            信息公告
            <dl style="left: -146px">
              <dd>
                <router-link to="/notice/museum" target="_blank"
                  >水博公告</router-link
                >
              </dd>

              <dt></dt>
              <dd>
                <router-link to="/notice/activity" target="_blank"
                  >活动公告</router-link
                >
              </dd>

              <dt></dt>

              <dd>
                <router-link to="/notice/collect" target="_blank"
                  >文物征集</router-link
                >
              </dd>
            </dl>
          </li>

          <li data-nav="1">
            联系我们
            <dl style="left: -146px">
              <dd>
                <router-link to="/contactUs/pre-book" target="_blank"
                  >参观预约</router-link
                >
              </dd>
              <dt></dt>
              <dd>
                <router-link to="/contactUs/guestbook" target="_blank"
                  >留言板</router-link
                >
              </dd>
              <dt></dt>
              <dd>
                <router-link to="/contactUs/volunteering" target="_blank"
                  >志愿服务</router-link
                >
              </dd>
            </dl>
          </li>
        </ul>
        <div class="nav_lists_bg"></div>
      </div>
      <div class="nav-search">
        <p class="wza">进入老年模式</p>
        <div class="nav_search_box">
          <input
            type="hidden"
            name="_token"
            value="cz4DL1ETSmBtXNDYj50XPpSoRQfUyrwNJSURgbEF"
          />
          <input
            type="text"
            class="text"
            name="q"
            id="q"
            v-model="searchKey"
            placeholder=" 请输入搜索内容 "
            value=""
          />
          <!-- <router-link to="/search/index" target="_blank"> -->
          <input type="submit" id="search_btn" value="" @click="submit()" />
          <!-- </router-link> -->
        </div>
      </div>
    </div>
    <div class="cbl">
      <ul>
        <li>
          <router-link to="/search/index" target="_blank">
            <img src="../assets/cbl-1.jpg" alt="" />
          </router-link>
        </li>
        <li>
          <a href="http://www.mwr.gov.cn/hd/jbts2018/"
            ><img src="../assets/btn_jubao.jpg" alt=""
          /></a>
        </li>
        <li class="to_top">
          <img src="../assets/cbl-6.jpg" alt="" />
        </li>
      </ul>
    </div>
    <div class="section">
      <div class="main">
        <router-view />
      </div>
    </div>
    <div class="xglj" v-if="$route.path == '/home' || $route.path == '/home/'">
      <div class="main">
        <div class="xglj-title">相关链接:</div>
        <div class="xglj-box">
          <select class="select">
            <option value="0">--水利部网--</option>
            <option value="http://www.mwr.gov.cn">中华人民共和国水利部</option>
          </select>
          <select class="select">
            <option value="0">--水利部直属--</option>
            <option value="http://www.cjw.gov.cn">长江水利委员会</option>
            <option value="http://www.yrcc.gov.cn">黄河水利委员会</option>
            <option value="http://www.hrc.gov.cn">淮河水利委员会</option>
            <option value="http://www.hwcc.gov.cn">海河水利委员会</option>
            <option value="http://www.pearlwater.gov.cn">珠江水利委员会</option>
            <option value="http://www.slwr.gov.cn">松辽水利委员会</option>
            <option value="http://www.tba.gov.cn">太湖流域管理局</option>
            <option value="http://www.zhsyj.org.cn">水利部综合事业局</option>
            <option value="http://xxzx.mwr.gov.cn">
              水利部信息中心（水利部水文水资源监测预报中心）
            </option>
            <option value="http://nsbd.mwr.cn">
              水利部南水北调规划设计管理局
            </option>
            <option value="http://fwj.mwr.cn">水利部机关服务局</option>
            <option value="http://www.giwp.org.cn/">
              水利部水利水电规划设计总院
            </option>
            <option value="http://www.iwhr.com/">中国水利水电科学研究院</option>
            <option value="http://xwxc.mwr.cn">水利部宣传教育中心</option>
            <option value="http://www.chinawater.com.cn">中国水利报社</option>
            <option value="http://www.waterpub.com.cn">
              中国水利水电出版社
            </option>
            <option value="http://www.waterinfo.com.cn">
              水利部发展研究中心
            </option>
            <option value="http://www.jsgg.com.cn">中国灌溉排水发展中心</option>
            <option value="http://jazx.mwr.cn/">
              水利部建设管理与质量安全中心
            </option>
            <option value="http://bec.mwr.cn/">水利部预算执行中心</option>
            <option value="http://szyzx.mwr.cn/">水利部水资源管理中心</option>
            <option value="http://www.nsbd.cn/">
              南水北调中线干线工程建设管理局
            </option>
            <option value="http://www.nsbddx.com/">南水北调东线总公司</option>
            <option value="http://www.ches.org.cn">中国水利学会</option>
            <option value="http://www.nhri.cn">南京水利科学研究院</option>
            <option value="http://www.icshp.org">国际小水电中心</option>
            <option value="http://www.xiaolangdi.com.cn">
              水利部小浪底水利枢纽管理中心
            </option>
            <option value="http://www.nwmc.cn">中国水利博物馆</option>
          </select>
          <select class="select">
            <option value="0">--浙江水利--</option>
            <option value="http://slt.zj.gov.cn/">浙江省水利厅</option>
            <option value="https://www.zjsw.cn/">省水文管理中心</option>
            <option value="http://qtj.slt.zj.gov.cn/">
              省钱塘江流域中心（省钱塘江管理局）
            </option>
            <option value="https://www.zjweu.edu.cn/">浙江水利水电学院</option>
            <option value="http://www.zjtongji.edu.cn/">
              浙江同济科技职业学院
            </option>
            <option value="http://www.nwmc.cn/">中国水利博物馆</option>
            <option value="http://www.zdwp.net/">省水利水电勘测设计院</option>
          </select>
        </div>
      </div>
    </div>

    <!-- <div class="xglj" v-if="$route.path == '/home' || $route.path == '/home/'">
      <div class="main">
        <div class="xglj-title">相关链接:</div>
        <div class="xglj-box">
          <select class="select">
            <option value="0">--水利部网--</option>
            <option>中华人民共和国水利部</option>
          </select>
          <select class="select">
            <option value="0">--水利部直属--</option>
            <option>长江水利委员会</option>
            <option>黄河水利委员会</option>
            <option>淮河水利委员会</option>
            <option>海河水利委员会</option>
            <option>珠江水利委员会</option>
            <option>松辽水利委员会</option>
            <option>太湖流域管理局</option>
            <option>水利部综合事业局</option>
            <option>水利部信息中心（水利部水文水资源监测预报中心）</option>
            <option>水利部南水北调规划设计管理局</option>
            <option>水利部机关服务局</option>
            <option>水利部水利水电规划设计总院</option>
            <option>中国水利水电科学研究院</option>
            <option>水利部宣传教育中心</option>
            <option>中国水利报社</option>
            <option>中国水利水电出版社</option>
            <option>水利部发展研究中心</option>
            <option>中国灌溉排水发展中心</option>
            <option>水利部建设管理与质量安全中心</option>
            <option>水利部预算执行中心</option>
            <option>水利部水资源管理中心</option>
            <option>南水北调中线干线工程建设管理局</option>
            <option>南水北调东线总公司</option>
            <option>中国水利学会</option>
            <option>南京水利科学研究院</option>
            <option>国际小水电中心</option>
            <option>水利部小浪底水利枢纽管理中心</option>
            <option>中国水利博物馆</option>
          </select>
          <select class="select">
            <option value="0">--浙江水利--</option>
            <option>浙江省水利厅</option>
            <option>省水文管理中心</option>
            <option>省钱塘江流域中心（省钱塘江管理局）</option>
            <option>浙江水利水电学院</option>
            <option>浙江同济科技职业学院</option>
            <option>中国水利博物馆</option>
            <option>省水利水电勘测设计院</option>
          </select>
        </div>
      </div>
    </div> -->
    <div class="footer">
      <div class="main">
        <dl>
          <dd class="set_home">设为首页</dd>
          <dt></dt>
          <dd class="add_fav">收藏本站</dd>
          <dt></dt>
          <dd>
            <a href="javascript:;">网站声明</a>
          </dd>
          <dt></dt>
          <dd>
            <a href="javascript:;">联系方式</a>
          </dd>
        </dl>
        <div class="copy_right">
          <p>中国水利博物馆 地址：浙江省杭州市萧山区水博大道1号 邮编：311215</p>
          <p>电话：0571-82863601</p>
          <p>
            <!-- 网站备案号：浙ICP备19003252号-1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;浙公网安备：33010902002945号 -->
            <a href="http://beian.miit.gov.cn" target="_blank"
              >网站备案号：浙ICP备19003252号-1</a
            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <a
              href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33010902002945"
              target="_blank"
              >浙公网安备：33010902002945号</a
            >
          </p>
        </div>
        <ul class="ewm_box">
          <li>
            <img src="../assets/icon_jdjb.jpg" alt="" />
            <p>举报</p>
            <div class="jb_box">
              <a
                href="http://www.mwr.gov.cn/hd/jbts2018/"
                target="_blank"
                style="color: #fff"
                >纪检举报</a
              >
              <br />
              <a
                href="http://supe.mwr.gov.cn/#/"
                target="_blank"
                style="color: #fff"
                >监督举报</a
              >
            </div>
          </li>
          <li>
            <img src="../assets/ewm_gfwx.jpg" alt="" />
            <p>官方微信</p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "LA",
  data() {
    return {
      searchKey: "",
    };
  },
  beforeUpdate() {
    console.log("hhhhh");
    this.$nextTick(() => {
      // this.initJS();
    });
  },
  mounted() {
    // this.initJS();
    function agingV(flag) {
      if (flag === null || flag === "0") {
        $(".aging_v").remove();
        $(".nav-search").find(".wza").html("进入老年模式");
      } else {
        $(".nav-search").find(".wza").html("退出老年模式");
        var head = document.getElementsByTagName("head")[0];
        var link = document.createElement("link");
        link.href = "/static/css/aging_v.css";
        link.rel = "stylesheet";
        link.type = "text/css";
        link.classList.add("aging_v");
        head.appendChild(link);
      }
    }

    $(function () {
      let aging_v = localStorage.getItem("aging_v");
      if (aging_v === null || aging_v === "0") {
        $(".nav-search").prepend('<p class="wza">进入老年模式</p>');
      } else {
        $(".nav-search").prepend('<p class="wza">退出老年模式</p>');
      }
      agingV(aging_v);
      $(".nav-search").on("click", ".wza", function () {
        let aging_v = localStorage.getItem("aging_v");
        if (aging_v === null || aging_v === "0") {
          agingV("1");
          localStorage.setItem("aging_v", "1");
        } else {
          agingV("0");
          localStorage.setItem("aging_v", "0");
        }
      });

      $(".header .nav li[data-nav='1']").hover(
        function () {
          $(".nav_lists_bg").show();
        },
        function () {
          $(".nav_lists_bg").hide();
        }
      );
      $(".header .nav li").each(function (i, e) {
        var dl = $(e).find("dl");
        var num_dd = $(e).find("dd").length;
        dl.css(
          "left",
          -(num_dd * 74 + (num_dd - 1) * 55 - $(".header .nav li").width()) / 2
        );
      });
      $(" .cbl ul li.to_top").click(function () {
        $("html, body").stop().animate({ scrollTop: 0 }, 500);
      });

      //设为首页
      function SetHome(obj, url) {
        try {
          obj.style.behavior = "url(#default#homepage)";
          obj.setHomePage(url);
        } catch (e) {
          if (window.netscape) {
            try {
              netscape.security.PrivilegeManager.enablePrivilege(
                "UniversalXPConnect"
              );
            } catch (e) {
              alert(
                "抱歉，此操作被浏览器拒绝！\n\n请在浏览器地址栏输入“about:config”并回车然后将[signed.applets.codebase_principal_support]设置为'true'"
              );
            }
          } else {
            alert(
              "抱歉，您所使用的浏览器无法完成此操作。\n\n您需要手动将【" +
                url +
                "】设置为首页。"
            );
          }
        }
      }

      //收藏本站
      function AddFavorite(title, url) {
        try {
          window.external.addFavorite(url, title);
        } catch (e) {
          try {
            window.sidebar.addPanel(title, url, "");
          } catch (e) {
            alert(
              "抱歉，您所使用的浏览器无法完成此操作。\n\n加入收藏失败，请使用Ctrl+D进行添加"
            );
          }
        }
      }

      $(".set_home").click(function () {
        SetHome(this, "http://www.nwmc.cn");
      });
      $(".add_fav").click(function () {
        AddFavorite("中国水利博物馆", "http://www.nwmc.cn");
      });
    });
    this.$eventBus.$on("initJS", () => {
      this.initJS();
    });
  },
  methods: {
    submit() {
      sessionStorage.initKey = this.searchKey;
      window.open("/search/index");
    },
    toHome() {
      this.$router.push({ path: "/home" });
      location.reload();
    },
    initJS() {
      const nav_lists_bg = document.querySelector(".nav_lists_bg");
      document.querySelectorAll(".nav li").forEach((item) => {
        item.addEventListener("mouseenter", (e) => {
          nav_lists_bg.style.display =
            e.target.dataset["nav"] == "1" ? "block" : "none";
        });
        item.addEventListener("mouseleave", () => {
          nav_lists_bg.style.display = "none";
        });
      });

      $(function () {
        // 焦点图
        var jd_li = $(".focus ul li");
        // var dian_i = $(".dian i");
        var _index = 0;
        var timer = null;
        function show(num) {
          jd_li.eq(num).fadeIn().siblings().fadeOut();
          dian_i.eq(num).addClass("on").siblings().removeClass("on");
        }
        // show(0);
        function go() {
          if (_index < jd_li.length - 1) {
            _index++;
            show(_index);
          } else {
            _index = 0;
            show(_index);
          }
        }
        // timer = setInterval(go, 4000);
        // $(".focus").hover(
        //   function () {
        //     clearInterval(timer);
        //   },
        //   function () {
        //     timer = setInterval(go, 4000);
        //   }
        // );
        /*  dian_i.on("hover", function () {
        _index =  dian_i.index($(this));
        show(_index);
    });*/
        // dian_i.hover(function () {
        //   _index = dian_i.index($(this));
        //   show(_index);
        // });

        // $(".dian").css("margin-left", -($(".dian").width() - 30) / 2 - 30);

        /*tit切换*/
        $(".sec_tit").each(function (i, e) {
          $(e).find(".more").eq(0).show();
        });
        $(".sec_items").each(function (i, e) {
          $(e).find(".sec_item").eq(0).show();
        });
        $(".sec_tit span").hover(function () {
          $(this).addClass("cur").siblings().removeClass("cur");
          $(".sec_items .sec_item")
            .eq($(".sec_tit span").index($(this)))
            .fadeIn()
            .siblings()
            .fadeOut();

          $(".sec_tit .more")
            .eq($(".sec_tit span").index($(this)))
            .parent()
            .find(".more")
            .hide();
          $(".sec_tit .more")
            .eq($(".sec_tit span").index($(this)))
            .show();
        });
        /*展览信息*/
        console.log($(".zlxx_box ul li").length, '$(".zlxx_box ul li").length');
        if ($(".zlxx_box ul li").length > 3) {
          var timer_1 = null;

          function scroll_1() {
            if ($(" .zlxx_box ul:animated").length == 0) {
              $(".zlxx_box ul")
                .stop(0)
                .animate({ marginLeft: -410 }, 600, function () {
                  $(" .zlxx_box ul")
                    .append($(" .zlxx_box ul li").eq(0))
                    .css("marginLeft", 0);
                });
            }
          }
          timer_1 = setInterval(scroll_1, 2600);
          $(".btn_prev").click(function () {
            if ($(" .zlxx_box ul:animated").length == 0) {
              clearInterval(timer_1);
              $(" .zlxx_box ul")
                .css("marginLeft", -410)
                .prepend($(" .zlxx_box ul li").eq(-1));
              $(" .zlxx_box ul")
                .stop(0)
                .animate({ marginLeft: 0 }, 600, function () {
                  timer_1 = setInterval(scroll_1, 2600);
                });
            }
          });
          $(".btn_next").click(function () {
            if ($(" .zlxx_box ul:animated").length == 0) {
              clearInterval(timer_1);
              $(".zlxx_box ul")
                .stop(0)
                .animate({ marginLeft: -410 }, 600, function () {
                  $(".zlxx_box ul")
                    .append($(" .zlxx_box ul li").eq(0))
                    .css("marginLeft", 0);
                  timer_1 = setInterval(scroll_1, 2600);
                });
            }
          });
        }
        /*sec2*/
        if ($(".sec_lf .sec2_box ul li").length > 2) {
          /*    var timer_2 = null;
         function scroll_2() {
         if ($(".sec_lf  .sec2_box ul:animated").length == 0) {
         $(".sec_lf .sec2_box ul").stop(0).animate({"marginLeft": -295}, 400, function () {
         $(".sec_lf  .sec2_box ul").append($(".sec_lf  .sec2_box ul li").eq(0)).css("marginLeft", 0);
         });
         }
         }
         timer_2 = setInterval(scroll_2, 2400);*/
          $(".sec_lf .btn_prev2").click(function () {
            if ($(".sec_lf  .sec2_box ul:animated").length == 0) {
              // clearInterval(timer_2);
              $(".sec_lf .sec2_box ul")
                .css("marginLeft", -295)
                .prepend($(".sec_lf .sec2_box ul li").eq(-1));
              $(".sec_lf .sec2_box ul")
                .stop(0)
                .animate({ marginLeft: 0 }, 400, function () {
                  // timer_2 = setInterval(scroll_2, 2400);
                });
            }
          });
          $(".sec_lf .btn_next2").click(function () {
            if ($(".sec_lf .sec2_box ul:animated").length == 0) {
              // clearInterval(timer_2);
              $(".sec_lf .sec2_box ul")
                .stop(0)
                .animate({ marginLeft: -295 }, 400, function () {
                  $(".sec_lf .sec2_box ul")
                    .append($(".sec_lf .sec2_box ul li").eq(0))
                    .css("marginLeft", 0);
                  // timer_2 = setInterval(scroll_2, 2400);
                });
            }
          });
        }
        if ($(".sec_rt .sec2_box ul li").length > 2) {
          $(".sec_rt .btn_prev2").click(function () {
            if ($(".sec_rt  .sec2_box ul:animated").length == 0) {
              //clearInterval(timer_3);
              $(".sec_rt .sec2_box ul")
                .css("marginLeft", -295)
                .prepend($(".sec_rt .sec2_box ul li").eq(-1));
              $(".sec_rt .sec2_box ul")
                .stop(0)
                .animate({ marginLeft: 0 }, 400, function () {
                  //timer_3 = setInterval(scroll_3, 2400);
                });
            }
          });
          $(".sec_rt .btn_next2").click(function () {
            if ($(".sec_rt .sec2_box ul:animated").length == 0) {
              //clearInterval(timer_3);
              $(".sec_rt .sec2_box ul")
                .stop(0)
                .animate({ marginLeft: -295 }, 400, function () {
                  $(".sec_rt .sec2_box ul")
                    .append($(".sec_rt .sec2_box ul li").eq(0))
                    .css("marginLeft", 0);
                  //timer_3 = setInterval(scroll_3, 2400);
                });
            }
          });
        }

        $(" .sec3_lists li").mouseover(function () {
          if (!$(this).hasClass("sel")) {
            $(this).addClass("sel").siblings().removeClass("sel");
            $(".sec3_lists  li").stop();
            $(this)
              .animate({ width: 672 }, 600, "linear")
              .siblings()
              .animate({ width: 120 }, 600, "linear");
          }
        });
        /*相关链接*/
        $("select").each(function (i, e) {
          e.onchange = function () {
            if (this.value != "0") {
              window.open(this.value);
            }
          };
        });
      });
    },
  },
  beforeDestroy() {
    document.querySelectorAll(".nav li").forEach((item) => {
      item.removeEventListener("mouseenter", () => {});
      item.removeEventListener("mouseleave", () => {});
    });
  },
};
</script>
<style scoped>
.footer .copy_right {
  width: 720px;
}
.footer {
  height: 180px;
  width: 100%;
  /* position: fixed;
  bottom: 0; */
}
.section {
  background: url(../assets/ejy_secbg.png) repeat;
}
.xglj {
  height: 94px;
  padding-top: 30px;
  background: #fff;
}
.xglj-title {
  float: left;
  width: 160px;
  margin: 0 -30px 0 120px;
  background: url(../assets/tit_icon.png) no-repeat left
    center;
  padding-left: 45px;
  font-size: 24px;
  /* font-family: SimSun; */
  color: #0068bb;
  font-weight: bold;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
</style>