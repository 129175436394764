<!--
 * @Author: rh 674704456@qq.com
 * @Date: 2022-12-15 18:33:29
 * @LastEditors: rh 674704456@qq.com
 * @LastEditTime: 2022-12-22 21:03:28
 * @FilePath: \water-museum\src\views\error\maintainMode.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="maintain">
    <div class="flex_vcenter">
      <img src="../../assets/maintain.png" />
      <div class="flex_column flex_vcenter">
        <h1 style="font-size: 40px;">maintenance mode</h1>
        <h2>UH OH! 网站维护中</h2>
      </div>
    </div>
  </div>
</template>
<script>
import { isMaintaining } from "@/api/index";
export default {
  name: "maintainMode",
  computed: {
    message() {
      return "找不到网页！";
    },
  },
  created() {
    clearInterval(this.timer)
    this.timer = null
    this.timer = setInterval(() => {
      isMaintaining().then((res) => {
        localStorage.setItem('maintainMode', res.data.isMaintaining)
        if (res.data.isMaintaining == 0) {
          this.$router.replace('/home')
        }
      })
    }, 10000)
  },
  beforeDestroy() {
    clearInterval(this.timer)
    this.timer = null
  }
};
</script>
<style scoped>
.maintain {
  height: 100vh;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
