/*
 * @Author: rh 674704456@qq.com
 * @Date: 2022-11-19 14:23:28
 * @LastEditors: rh 674704456@qq.com
 * @LastEditTime: 2022-12-22 21:57:47
 * @FilePath: \water-museum\src\main.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from 'vue'
import App from './App.vue'
import router from '@/router'
import Element from "element-ui";
import store from '@/store'
import Pagination from '@/components/Pagination'
// import moment from 'moment';
import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/css/element.css'
import "@/assets/css/index.css";
import '@/assets/css/common.css'
import '@/assets/css/main.css'
import "@/assets/css/quill.core.css";
import "@/assets/css/quill.snow.css";
import "@/assets/css/quill.bubble.css";
import "@/assets/css/quill-better-table.css";
import './permission' // permission control
import SlideVerify from 'vue-monoplasty-slide-verify';

Vue.use(SlideVerify);
Vue.component('Pagination', Pagination)
Vue.use(Element);
Vue.prototype.$moment = window.moment
Vue.prototype.$eventBus = new Vue()
Vue.config.productionTip = false
new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')
