/*
 * @Author: rh 674704456@qq.com
 * @Date: 2022-12-19 19:23:58
 * @LastEditors: rh 674704456@qq.com
 * @LastEditTime: 2022-12-23 11:16:53
 * @FilePath: \water-museum\src\api\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import request from '@/utils/request'

// 维护模式
export function isMaintaining(query) {
    return request({
        url: '/pc/isMaintaining',
        method: 'get',
        params: query
    })
}
// 首页
export function home(query) {
    return request({
        url: '/pc/index',
        method: 'get',
        params: query
    })
}

// 查询文章详细
export function articles(id) {
    return request({
        url: '/pc/articles/' + id,
        method: 'get'
    })
}

// 查询文章列表
export function articlesList(query) {
    return request({
        url: '/pc/articles/selectArticlesList',
        method: 'get',
        headers: { Access: 'AUTH20230428001' },
        params: query
    })
}

// 查询轮播列表
export function carouselsList(query) {
    return request({
        url: '/pc/carousels/list',
        method: 'get',
        params: query
    })
}

// 查询视频类型列表
export function videoCategorys(query) {
    return request({
        url: '/pc/videoCategorys/list',
        method: 'get',
        params: query
    })
}

// 全局搜索
export function search(query) {
    return request({
        url: '/pc/search/',
        method: 'get',
        params: query
    })
}

// 提交留言
export function addMessages(data) {
    return request({
        url: '/pc/messages/add',
        method: 'post',
        data: data
    })
}

// 黑白模式
export function isBlack(query) {
    return request({
        url: '/pc/configKey/sys_mourning',
        method: 'get',
        params: query
    })
}

// 轮播详情
export function carouselsDetail(id) {
    return request({
        url: '/pc/carousels/' + id,
        method: 'get',
    })
}

// 新增访客量
export function addVisitors() {
    return request({
        url: '/pc/visitors/add',
        method: 'get',
    })
}