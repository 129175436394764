/*
 * @Author: rh 674704456@qq.com
 * @Date: 2022-10-31 11:51:31
 * @LastEditors: rh 674704456@qq.com
 * @LastEditTime: 2022-12-23 11:50:48
 * @FilePath: \water-museum\src\router\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/Layout'
import maintainMode from '@/views/error/maintainMode.vue'
Vue.use(VueRouter)
export const routes = [
    {
        path: 'redirect',
        component: Layout,
        hidden: true,
        children: [
            {
                path: '/redirect/:path(.*)',
                component: () => import('@/views/redirect')
            }
        ]
    },
    {
        path: 'home',
        meta: {
            title: '首页'
        },
    },
    {
        path: 'enterMuseum/speech',
        meta: {
            title: '馆长致辞'
        }
    },
    {
        path: 'enterMuseum/survey',
        meta: {
            title: '水博概况'
        }
    },
    {
        path: 'enterMuseum/duty',
        meta: {
            title: '主要职责'
        }
    },
    {
        path: 'enterMuseum/structure',
        meta: {
            title: '组织架构'
        }
    },
    {
        path: 'enterMuseum/chronicleOfEvents',
        meta: {
            title: '大事记'
        }
    },
    {
        path: 'information/index',
        meta: {
            title: '水博资讯'
        }
    },
    {
        path: 'overview/index',
        meta: {
            title: '水利纵览'
        }
    },
    {
        path: 'dynamic/index',
        meta: {
            title: '文博动态'
        }
    },
    {
        path: 'exhibition/introduce',
        meta: {
            title: '展览介绍'
        }
    },
    {
        path: 'exhibition/collections',
        meta: {
            title: '藏品鉴赏'
        }
    },
    {
        path: 'culture/education',
        meta: {
            title: '社会教育'
        }
    },
    {
        path: 'culture/heritage',
        meta: {
            title: '水利遗产'
        }
    },
    {
        path: 'culture/scholarship',
        meta: {
            title: '学术研究'
        }
    },
    {
        path: 'culture/achievement',
        meta: {
            title: '学术成果'
        }
    },
    {
        path: 'culture/culturalBoutique',
        meta: {
            title: '文创精品'
        }
    },
    {
        path: 'culture/integration',
        meta: {
            title: '水利文献集成'
        }
    },
    {
        path: 'culture/communicate',
        meta: {
            title: '交流合作'
        }
    },
    {
        path: 'culture/popularScience',
        meta: {
            title: '水利科普'
        }
    },
    {
        path: 'culture/partybuilding',
        meta: {
            title: '党建园地'
        }
    },
    {
        path: 'notice/museum',
        meta: {
            title: '水博公告'
        }
    },
    {
        path: 'notice/activity',
        meta: {
            title: '活动公告'
        }
    },
    {
        path: 'notice/collect',
        meta: {
            title: '文物征集'
        }
    },
    {
        path: 'contactUs/pre-book',
        meta: {
            title: '参观预约'
        }
    }, {
        path: 'contactUs/guestbook',
        meta: {
            title: '留言板'
        }
    }, {
        path: 'contactUs/volunteering',
        meta: {
            title: '志愿服务'
        }
    }, {
        path: 'contactUs/trafficguide',
        meta: {
            title: '交通指南'
        }
    },
    {
        path: 'resources/picture',
        meta: {
            title: '图片集锦'
        }
    },
    {
        path: 'resources/video',
        meta: {
            title: '视频集锦'
        }
    },
    {
        path: 'search/index',
        meta: {
            title: '搜索'
        }
    },
    {
        path: 'information/article',
        meta: {
            title: '水博资讯文章'
        }
    },
    {
        path: 'overview/article',
        meta: {
            title: '水利纵览文章'
        }
    },
    {
        path: 'dynamic/article',
        meta: {
            title: '文博动态文章'
        }
    },
    {
        path: 'exhibition/article',
        meta: {
            title: '展览介绍文章'
        }
    },
    {
        path: 'culture/article',
        meta: {
            title: '水利文化文章'
        }
    },
    {
        path: 'notice/article',
        meta: {
            title: '信息公告文章'
        }
    },
    {
        path: 'contactUs/article',
        meta: {
            title: '志愿服务文章'
        }
    },
    {
        path: 'search/article',
        meta: {
            title: '轮播详情文章'
        }
    },
    {
        path: 'resources/article',
        meta: {
            title: '图片锦集详情文章'
        }
    },
]
const getRoutes = (routes) => {
    const newroutes = routes.map(item => {
        item.component = (resolve) => require(['@/views/' + item.path], resolve)
        return item
    })
    return newroutes
}
export const constantRoutes = [{
    path: '/',
    component: Layout,
    hidden: true,
    redirect: '/home',
    children: getRoutes(routes)
},
{
    path: '/maintainMode',
    component: maintainMode,
},
{
    path: '*',
    component: () => import('@/views/error/404'),
    hidden: true
},]

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => {
        if (err && err.name != 'NavigationDuplicated') this.replace('/404')
    })
}
const router = new VueRouter({
    mode: 'history', // require service support
    scrollBehavior: () => ({
        y: 0
    }),
    routes: constantRoutes
})

export default router
