<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <el-pagination
    background
    layout="total, prev, pager, next"
    :page-size="12"
    v-bind="$attrs"
    v-on="$listeners"
  >
  </el-pagination>
</template>
<script>
export default {
  name: "pagination",
  data() {
    return {};
  },
};
</script>
<style scoped>
::v-deep .el-pagination {
  text-align: center;
  padding: 60px 0;
  margin: 0 !important;
}
</style>